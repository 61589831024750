<template>
    <div class="col-md-12">
        <div>
            <table class="table table-bordered">
                <tr>
                    <th style="word-break: break-word">Thời gian</th>
                    <th style="word-break: break-word">Lợi nhuận văn phòng còn lại</th>
                    <th style="word-break: break-word">Tổng tài sản</th>
                    <th style="word-break: break-word">Văn phòng</th>
                </tr>
                <tr v-for="(item, index) in data" :key="index">
                    <td style="word-break: break-word; white-space: nowrap">{{ item.date }} </td>
                    <td style="word-break: break-word; white-space: nowrap">{{ item.remaining_profit |formatVND}}</td>
                    <td style="word-break: break-word; white-space: nowrap">{{ item.total |formatVND}}</td>
                    <td style="word-break: break-word">{{ item.branch_name }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";

export default {
    name: 'ModalFinancialReport',
    props: {
        data: {
            type: Object,
            default: () => {
                return null;
            }
        },
    },
    methods: {
      formatVND,
        changeTime(item) {
            let time = item.year + '/' + item.month;
            return moment(time).format('MM/YYYY');
        }
    }
}
</script>