<template>
    <div class="col-md-12">
        <h3>Tổng vốn góp: {{totalDetails |formatVND}}</h3>
        <div>
            <table class="table table-bordered">
                <tr>
                    <th style="word-break: break-word">Bậc nhân sự </th>
                    <th style="word-break: break-word">Thời gian % cổ phần thay đổi</th>
                    <th style="word-break: break-word">% cổ phần thay đổi</th>
                    <th style="word-break: break-word">Tổng giá trị tài sản</th>
                    <th style="word-break: break-word">Giá trị cổ phần</th>
                    <th style="word-break: break-word">Văn phòng</th>
                </tr>
                <tr v-for="(item, index) in data" :key="index">
                    <td style="word-break: break-word">{{ item.business_level }}</td>
                    <td style="word-break: break-word; white-space: nowrap">{{ item.date |formatMonthYear }}</td>
                    <td style="word-break: break-word; white-space: nowrap">{{ item.diff_percent ? item.diff_percent + '%' : '' }} </td>
                    <td style="word-break: break-word; white-space: nowrap">{{ item.total_assets |formatVND}}</td>
                    <td style="word-break: break-word; white-space: nowrap">{{ item.stock_value |formatVND}}</td>
                    <td style="word-break: break-word">{{ item.branch_name }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalFinancialContribute',
    props: {
        data: {
            type: Array,
            default: () => {
                return [];
            }
        },
        totalDetails: {
            type: Number,
            default: () => {
                return 0;
            }
        },
    }
}
</script>