<template>
    <div class="col-md-12">
        <h3>Tổng: {{ totalDetails |formatVND }}</h3>
        <div>
            <table class="table table-bordered">
                <tr>
                    <th style="word-break: break-word">Thời gian góp vốn</th>
                    <th style="word-break: break-word">Số tiền góp </th>
                    <th style="word-break: break-word">Văn phòng</th>
                </tr>
                <tr v-for="(item, index) in data" :key="index">
                    <td style="word-break: break-word; white-space: nowrap;">{{ item.date |formatMonthYear }}</td>
                    <td> 
                        <span style="word-break: break-word; white-space: nowrap" :class="item.action ? 'text-danger' : 'text-success'" class="text-white rounded p-2">
                            {{item.action ? '-' : '+'}}
                            {{ item.money |formatVND }}</span>
                    </td>
                    <td style="word-break: break-word">
                        {{ item.branch_name }}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalFinancialActualContribute',
    props: {
        data: {
            type: Array,
            default: () => {
                return [];
            }
        },
        totalDetails: {
            type: Number,
            default: () => {
                return 0;
            }
        },
    }
}
</script>

<style>
</style>