<template>
    <div class="col-md-12">
        <h3>Số tiền: {{ totalDetails |formatVND}}</h3>
        <div>
            <table class="table table-bordered">
                <tr>
                    <th style="word-break: break-word">Người đề xuất</th>
                    <th style="word-break: break-word">Nội dung chi</th>
                    <th style="word-break: break-word; white-space: nowrap">Số tiền</th>
                    <th style="word-break: break-word">Thời gian tính chi văn phòng</th>
                    <th style="word-break: break-word">Văn phòng</th>
                </tr>
                <tr v-for="(item, index) in data" :key="index">
                    <td style="word-break: break-word">{{ item.user_name }}</td>
                    <td style="word-break: break-word">{{ item.content }}</td>
                    <td style="word-break: break-word; white-space: nowrap">{{ item.money |formatVND}}</td>
                    <td style="word-break: break-word; white-space: nowrap">{{ item.date }}</td>
                    <td style="word-break: break-word">{{ item.branch_name }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalOfficeExpenseReport',
    props: {
        data: {
            type: Array,
            default: () => {
                return [];
            }
        },
        totalDetails: {
            type: Number,
            default: () => {
                return 0;
            }
        },
    }
}
</script>